/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

body, html {
  font-family: sans-serif;
}

#toast-container > div {
  width: 800px;
}

mat-cell .mat-button,
mat-header-cell .mat-raised-button {
  height: 2em;
  line-height: 2em;
  padding: 0 20px;
  margin-right: 0.2em;
}

mat-cell .mat-button .material-icons,
mat-header-cell .mat-raised-button .material-icons {
  font-size: 1.3em;
  margin-top: -0.2em;
  margin-right: 0.2em;
}

.spacer {
  flex: 1 1 auto;
}

.mat-toolbar.header.mat-toolbar.mat-toolbar-single-row {
  background-color: white;
  padding: 0;
}

.header-text {
  font-weight: normal;
}

.header-input {
  font-size: 14px;
}

mat-table .mat-header-cell {
  display: flex;
  color: black;
  font-weight: bold;
}

.form {
  padding-top: 10px;
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
}

.form-40-width {
  width: 40%;
}

.form-full-width {
  width: 100%;
}

div.simple-notification-wrapper {
  font-family: sans-serif;
  width: 500px;
}
